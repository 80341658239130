import { useEffect, useState } from 'react';

let isSSRGlobal = true;

export let useIsSSR = () => {
  let [isSSR, setIsSSR] = useState(isSSRGlobal);

  useEffect(() => {
    if (typeof window == 'undefined') return;
    setIsSSR(false);
    isSSRGlobal = false;
  }, []);

  return isSSR;
};
