import { VaporProvider } from '@vapor/ui';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useIsSSR } from '../hooks/useIsSSR';
import '../styles/global.css';
import '../styles/reset.css';
import '../styles/variables.css';

function MyApp({ Component, pageProps }) {
  let isSSR = useIsSSR();
  let router = useRouter();

  return (
    <VaporProvider ssr>
      <Component {...pageProps} />

      <NextSeo
        title="Weekrise - A beautiful weekly task planner and to-do list."
        description="Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity."
        canonical={`https://weekrise.com${router.pathname}`}
        openGraph={{
          url: 'https://weekrise.com/',
          title: 'Weekrise - A beautiful weekly task planner and to-do list.',
          description: 'Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity.',
          images: [
            {
              url: 'https://weekrise.com/og.png'
            }
          ],
          site_name: 'Weekrise - A weekly task planner and to-do list.',
          type: 'website'
        }}
        twitter={{
          handle: '@weekrise',
          site: '@weekrise',
          cardType: 'summary_large_image'
        }}
      />

      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#ffcccc" />
        <meta name="theme-color" content="#ffcccc"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'WebApplication',
              applicationCategory: 'Productivity, Scheduling, Calendar, Task Management, Project Management',
              name: 'Weekrise - A beautiful weekly task planner and to-do list.',
              operatingSystem: 'all',
              browserRequirements: 'Requires Javascript, HTML5 and CSS3 support',
              description: 'Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity.',
              url: 'https://weekrise.com',
              screenshot: 'https://weekrise.com/og.png',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '5',
                reviewCount: '3'
              },
              offers: {
                '@type': 'AggregateOffer',
                offeredBy: {
                  '@type': 'Organization',
                  name: 'Weekrise',
                  url: 'https://weekrise.com/',
                  logo: {
                    '@type': 'ImageObject',
                    url: 'https://weekrise.com/android-chrome-512x512.png',
                    width: '512px',
                    height: '512px',
                    description: 'Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity.',
                    sameAs: ['https://twitter.com/weekrise', 'https://www.producthunt.com/products/weekrise', 'https://www.producthunt.com/posts/weekrise-1', 'https://www.producthunt.com/posts/weekrise-beta']
                  }
                },
                highPrice: '12',
                lowPrice: '0',
                offerCount: '3',
                priceCurrency: 'USD',
                priceSpecification: [
                  {
                    '@type': 'UnitPriceSpecification',
                    price: '0.00',
                    priceCurrency: 'USD',
                    name: 'Weekrise FREE'
                  },
                  {
                    '@type': 'UnitPriceSpecification',
                    price: '5',
                    priceCurrency: 'USD',
                    name: 'Weekrise Pro',
                    referenceQuantity: {
                      '@type': 'QuantitativeValue',
                      value: '1',
                      unitCode: 'MON'
                    }
                  },
                  {
                    '@type': 'UnitPriceSpecification',
                    price: '12',
                    priceCurrency: 'USD',
                    name: 'Weekrise Team',
                    referenceQuantity: {
                      '@type': 'QuantitativeValue',
                      value: '1',
                      unitCode: 'MON'
                    }
                  }
                ]
              },
              creator: {
                '@type': 'Organization',
                url: 'https://weekrise.com/',
                name: 'Weekrise',
                alternateName: 'weekrise.com',
                logo: {
                  '@type': 'ImageObject',
                  url: 'https://weekrise.com/android-chrome-512x512.png',
                  width: '512px',
                  height: '512px',
                  description: 'Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity.',
                  sameAs: ['https://twitter.com/weekrise', 'https://www.producthunt.com/products/weekrise', 'https://www.producthunt.com/posts/weekrise-1', 'https://www.producthunt.com/posts/weekrise-beta']
                }
              }
            })
          }}
        />

        {/* <title>Weekrise - A weekly task planner and to-do list.</title>
        <meta name="title" content="Weekrise - A weekly task planner and to-do list." />
        <meta
          name="description"
          content="Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://weekrise.com/" />
        <meta property="og:title" content="Weekrise - A weekly task planner and to-do list." />
        <meta
          property="og:description"
          content="Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity."
        />
        <meta property="og:image" content="https://weekrise.com/og.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://weekrise.com/" />
        <meta property="twitter:title" content="Weekrise - A weekly task planner and to-do list." />
        <meta
          property="twitter:description"
          content="Weekrise is a FREE weekly task planner and to-do list for you, your team, and your family. It organizes tasks as a weekly calendar and supports the POMODORO-Technique. Weekrise is simple yet packed with useful features to boost your productivity."
        />
        <meta property="twitter:image" content="https://weekrise.com/og.png"></meta> */}

        {/* <link rel="stylesheet" href="/inter/index.css" /> */}

        <style>
          {`
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("/inter/Inter-Regular.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("/inter/Inter-Italic.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("/inter/Inter-Bold.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("/inter/Inter-Black.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
        url("/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}
`.replaceAll('\n', ' ')}
        </style>

        <style>
          {`
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
      url('/playfair/playfair-display-v29-latin-700.woff2') format('woff2'), 
      url('/playfair/playfair-display-v29-latin-700.woff') format('woff'); 
}

.play {
  font-family: 'Playfair Display', serif;
  font-style: oblique;
  font-weight: 700;
}
        `.replaceAll('\n', ' ')}
        </style>
      </Head>
    </VaporProvider>
  );
}

export default MyApp;
